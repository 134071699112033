import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Button, Icon, Link, Pill, Select } from '@opengov/component-library/capital';
import { PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Button",
  "activeTab": "components",
  "componentId": "button",
  "description": "Buttons are used to initiate an action or open a new page."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2><strong parentName="h2">{`Variants`}</strong></h2>
    <PatternExample example={<Button mdxType="Button">Example</Button>} title="Neutral Button - Most Used" description={"The neutral buttons style is most frequently used"} mdxType="PatternExample" />
    <PatternExample example={<Button variant="primary" mdxType="Button">Example</Button>} title="Blue Button - Less Used" description={"Use blue buttons for the primary action. Primary buttons should only appear once per screen (not including the application header or in a modal dialog)"} mdxType="PatternExample" />
    <PatternExample example={<Button variant="naked" mdxType="Button">Example</Button>} title="Naked Button - Less Used" description={"Use on special occasions, like birthdays and beach vacations"} mdxType="PatternExample" />
    <PatternExample example={<Button variant="destructive" mdxType="Button">Example</Button>} title="Destructive" description={"For actions that could have destructive effects on the user’s data  (delete, remove, etc.)."} mdxType="PatternExample" />
    <h4><strong parentName="h4">{`Icon + Text - Most Used`}</strong></h4>
    <p>{`In most cases, we don’t use stand-alone icons. Many users are confused by stand-alone icons. Eliminate guesswork by pairing icons, even familiar ones, with text.  When to pair an icon with text:`}</p>
    <ul>
      <li parentName="ul">{`If the icon increases meaning and usability`}</li>
      <li parentName="ul">{`If it’s what’s familiar and what the user is scanning for`}</li>
      <li parentName="ul">{`If other links in the same group are paired with icons, for consistency, pair text links with icons across the group`}</li>
    </ul>
    <p><em parentName="p">{`For consistency, all items in a group should have icons.`}</em></p>
    <h4><strong parentName="h4">{`Icon Only - Seldom Used`}</strong></h4>
    <ul>
      <li parentName="ul">{`Use icons only if the meaning can be understood more quickly without text`}</li>
      <li parentName="ul">{`Use if icons are familiar and a functional group – for instance, a set of controls like text editing or video controls`}</li>
      <li parentName="ul">{`Use if space is limited (e.g. collapsed state, mobile, etc.)`}</li>
    </ul>
    <p><em parentName="p">{`In the above example, users are not familiar with all chart type names. Spelling it out might slow them down. Just an icon works better.`}</em></p>
    <h2><strong parentName="h2">{`Configuration`}</strong></h2>
    <h3>{`Default Button - Most Used`}</h3>
    <PatternExample example={<Button mdxType="Button">Example</Button>} title="Default" description={"For the principal call to action on the page. Primary buttons should only appear once per screen (not including the application header or in a modal dialog)."} mdxType="PatternExample" />
    <UsageBlock mdxType="UsageBlock">
      <UsageBlock.Correct>
        <p>Most of time, use default size elements -Designed for maximum legibility, use this size   for all primary tasks, including within larger components, like tables.</p>
        <p>Unifying height and text size - For the most part, there is a single type size, line height and total height across all Default Sized Elements (filters, fields, buttons, etc.) – 38 px height. That single height means a mix of elements can be arranged in a vertical bar and align precisely, including type on single baseline.</p>
      </UsageBlock.Correct>
      <UsageBlock.Incorrect>
        <p>Use more than one blue button per screen.</p>
        <p>Use more than one destructive button per screen.</p>
        <p>Resist adding an icon unless it increases usability.</p>
      </UsageBlock.Incorrect>
    </UsageBlock>
    <h4><strong parentName="h4">{`Quick Reference Specs`}</strong></h4>
    <ul>
      <li parentName="ul">{`Type: 16pt Benton Medium`}</li>
      <li parentName="ul">{`Icon Size: 16x16`}</li>
      <li parentName="ul">{`Total Height: 38px`}</li>
    </ul>
    <h3>{`Small Button - Less Used`}</h3>
    <PatternExample example={<Button size="small" mdxType="Button">Example</Button>} title="Small" description={"For the principal call to action on the page with tight space."} mdxType="PatternExample" />
    <UsageBlock mdxType="UsageBlock">
      <UsageBlock.Correct>
        <p>Limit use of small buttons, overall.</p>
        <p>Use for complex menus - Small buttons are reserved for components that reveal on hover or click, for example: complex menus, drop downs, and tooltips. These components are typically smaller in size with tight padding, so small buttons feel balanced. Since space is tight for these components, smaller buttons make functional sense too.</p>
      </UsageBlock.Correct>
      <UsageBlock.Incorrect>
        <p>Avoid small buttons in primary experience.</p>
        <p>Do not mix small and default elements.</p>
      </UsageBlock.Incorrect>
    </UsageBlock>
    <h4><strong parentName="h4">{`Quick Reference Specs`}</strong></h4>
    <ul>
      <li parentName="ul">{`Type: 14pt Benton Medium`}</li>
      <li parentName="ul">{`Icon Size: 16x16`}</li>
      <li parentName="ul">{`Total Height: 28px`}</li>
    </ul>
    <h3>{`Large Button - Seldom Used`}</h3>
    <PatternExample example={<Button size="large" mdxType="Button">Example</Button>} title="Large" description={"Use a large button if an action needs emphasis. A possible use for a large button is an empty states, when the user has yet to create a single report, budget, etc."} mdxType="PatternExample" />
    <UsageBlock mdxType="UsageBlock">
      <UsageBlock.Correct>
        <p>Use a large button if an action needs emphasis.</p>
        <p>First action, getting started. A possible use for a large button is an empty states, when the user has yet to create a single report, budget, etc.</p>
      </UsageBlock.Correct>
      <UsageBlock.Incorrect>
        <p>Do not use large buttons in primary experience.</p>
        <p>Do not mix large and default buttons.</p>
      </UsageBlock.Incorrect>
    </UsageBlock>
    <h4><strong parentName="h4">{`Quick Reference Specs`}</strong></h4>
    <ul>
      <li parentName="ul">{`Type: 20pt Benton Medium`}</li>
      <li parentName="ul">{`Icon Size: 16x16`}</li>
      <li parentName="ul">{`Total Height: 40px`}</li>
    </ul>
    <h2>{`Layout`}</h2>
    <PatternExample example={<>
    <p><Button mdxType="Button"><Icon icon="action-print" mdxType="Icon" /> Print</Button></p>
    <p><Pill icon="operator-equal" text="Filter equals" mdxType="Pill" /></p>
  </>} title="With text" description={"kdfsldkfjlskdjfsldkfjlskdf"} mdxType="PatternExample" />
    <PatternExample width="full" example={<Button mdxType="Button"><Icon icon="action-print" mdxType="Icon" /></Button>} title="Toolbar" description={"kdfsldkfjlskdjfsldkfjlskdf"} mdxType="PatternExample" />
    <h2>{`Related components`}</h2>
    <p>{`Button Group`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      